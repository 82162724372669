@import '@xpo-ltl/ngx-ltl-core/styles/variables';

$extra-bold: 900; // No ngx variable for extra bold!
// this is really true bold! ngx-ltl variable for bold maps to 500 which is not correct meaning of bold!
$app-fontWeight--bold: 700;

$xpo-grey--250: #CCCCCC;

$warning-color: $xpo-yellow--350;
$warning-bg-color: rgba(255, 226, 200, 0.08);
$divider-color: rgba(0, 0, 0, 0.12);

$pnd-snackbar--info: rgba(2, 136, 209, 1);

$pnd-fontWeight--bold: 700;
$pnd-fontSize--small: 12px;

$pnd-editable-cell-color: #FFF8BC;

// this color is in the design system but not in ngx yet
$xpo-edge-black: #000;

$xpo-fontFamily--robotoBold: 'Roboto-Bold', 'Roboto Bold', 'Roboto', sans-serif;

$streetView-button-width: 40px;

// --- SIC, Driver, and Remarks width in create/update trip
$xpo-createUpdateTrip-sic-driver-remarks-width: 370px;
