@import '@xpo-ltl/ngx-ltl-core/styles/variables';

$stepLevel: (1, 2, 3, 4);
$topLevelSupported: 4;

@mixin getStepIndex($step) {
  $index: calc(#{$topLevelSupported} - #{$step});

  mat-step-header {
    &[aria-posinset='#{$step}'] {
      z-index: $index;
    }
  }
}

@each $level in $stepLevel {
  @include getStepIndex($level);
}

mat-horizontal-stepper {
  .mat-horizontal-stepper-header-container {
    align-items: center;
    border: 1px solid $xpo-grey--300;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    height: 68px;
    width: 100%;

    .mat-stepper-horizontal-line {
      display: none;
    }

    .mat-horizontal-stepper-header {
      align-items: center;
      background: $xpo-grey--100;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      flex-shrink: 1;
      height: 100%;
      min-width: 267px;
      position: relative;

      &:not(:last-child) {
        -webkit-clip-path: polygon(0 0, calc(100% - 16px) -2%, 100% 50%, calc(100% - 16px) 102%, 0 100%);
        clip-path: polygon(0 0, calc(100% - 16px) -2%, 100% 50%, calc(100% - 16px) 102%, 0 100%);
      }

      &:not(:first-child) {
        margin-left: -16px;
        padding-left: 24px;
        padding-right: 24px;
        position: relative;

        &::before {
          background: $xpo-grey--300;
          bottom: 0;
          -webkit-clip-path: polygon(0 0, calc(100% - 16px) -2%, 100% 50%, calc(100% - 16px) 102%, 0 100%);
          clip-path: polygon(0 0, calc(100% - 16px) -2%, 100% 50%, calc(100% - 16px) 102%, 0 100%);
          content: '';
          display: block;
          left: 1px;
          position: absolute;
          top: 0;
          width: 16px;
        }
      }

      &[aria-selected='true'] {
        background: $ngx-menu-backgroundColor;
      }

      .mat-step-icon {
        font-size: 2.142rem;
        font-weight: $app-fontWeight--bold;
        margin-right: 16px;
        width: 16px;

        &.mat-step-icon-selected {
          color: $xpo-blue--800;
        }
      }

      .mat-step-label {
        border-left: 1px solid $xpo-grey--300;
        display: flex;
        flex-direction: column;
        font-size: $xpo-header-fontSize--large;
        font-weight: $app-fontWeight--bold;
        height: 44px;
        justify-content: center;
        padding: 2px 0 2px 8px;
      }
    }
  }

  .mat-horizontal-content-container {
    padding: $xpo-contentSpacing 0 $xpo-contentSpacing 0;
  }
}
